<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        Daftar Try Out Gratis
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>{{ ujian.nama_paket }}</b></h2>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">
                    		<router-link :to='tipe_ujian == "ujian" ? "/ujian-trial" : "/soal-mapel"' class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Halaman Pilih Paket Try Out
                    		</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
			<table class="table table-hover" ref="tblujian">
			<thead>
				<tr>
				<th>TRY OUT</th>
                <th>DURASI</th>
                <th>JUMLAH SOAL</th>
                <th>PENGERJAAN</th>
                <th>TINDAKAN</th>
				</tr>
			</thead>
			<tbody @click="handleClick"></tbody>
			</table>
        </div>
    </section>
</template>
<script>
	import { createTable, authFetch } from "@/libs/hxcore";
    export default {
    name: 'UjianTrialList',
    data: () => ({
        ujian: {},
        roles: "",
        tipe_ujian: '',
    }),
    created: function () {
        let id = this.$route.params.id;
		this.tipe_ujian = this.$route.params.type;
        if (this.tipe_ujian == 'latihan') {
            this.loadMapel(id);
        } else {
            this.loadPaket(id);
        }

		this.roles = this.$route.meta.roles;
    	console.log("load initial data", this.$route);
    },
    methods: {
        loadMapel(id){
			authFetch("/siswa/ujian/latihan_list_trial/" + id).then((res) => {
				res.json().then((json) => {
				this.ujian = json;
				});
			});
        },
        loadPaket(id){
			authFetch("/siswa/ujian/list_trial/" + id).then((res) => {
				res.json().then((json) => {
				this.ujian = json;
				});
			});
        },
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				this.$router.push({ path: "/permit/" + e.target.dataset.id });
				return false;
			}

			if (e.target.matches(".btn-info")) {
				if (e.target.dataset.action == "view") {
					this.$router.push("/ujian-start/" + e.target.dataset.id);
				}
			}

			if (e.target.matches(".btn-success")) {
				if (e.target.dataset.action == "view") {
					this.$router.push("/ujian-finish/" + e.target.dataset.id)
				}
			}

			if (e.target.matches(".btn-success")) {
				if (e.target.dataset.action == "view") {
					this.$router.push("/ujian-finish/" + e.target.dataset.id)
				}
			}

			if (e.target.matches(".btn-primary")) {
				if (e.target.dataset.action == "rank") {
					// ujian/ranking/mapel_id/code
					this.$router.push("/ujian/ranking/"+ e.target.dataset.mapelid +"/" + e.target.dataset.id);
				}
			}
		},
    },
    mounted(){
	const e = this.$refs;
	let self = this;

	this.table = createTable(e.tblujian, {
		title: "",
		language: {
			zeroRecords: "Tidak ada ujian."
		},
        scrollX: true,
		selectedRowClass: '',
		roles: ["read"],
		ajax: "/siswa/ujian/ujian_list_trial",
		searching: true,
		paramData: function(d) {
			d.paket_id = self.$route.params.id;
			d.type = self.$route.params.type;
		},
		columns: [
			{ data: "ujian_title" },
			{ data: "durasi",
			render: function (data, type, row, meta) {
				return `<h5>${!data ? '60 Menit' : data + ' Menit'}</h5>`;
			}, },
			{ data: "total_soal",
			render: function (data, type, row, meta) {
				return `<h5>${data} Soal</h5>`;
			}, },
			{ data: "total_ujian",
			render: function (data, type, row, meta) {
				var total = data && data != null && data != 'null' ? data : 0
				if (self.$route.params.type == 'latihan') {
					return `<h6>${total}x dari 1x percobaan</h6>`;
				} else {
					return `<h6>${total}x dari 1x pengerjaan</h6>`;
				}
			}, },
			{ data: "action",
			sortable: false,
			render: function (data, type, row, meta) {
				if (self.$route.params.type == 'ujian') {
					if (row.total_ujian >= 1) {
					return `<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-success" data-action="view" data-id="` + row.ujian_code + `"> Pembahasan </button>
					<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-primary" data-action="rank" data-id="` + row.ujian_code + `" data-mapelid="` + row.mapel_id + `">Ranking List</button>`;
					}
				}

				if (self.$route.params.type == 'latihan') {
					if (row.total_ujian >= 1) {
					return `<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-success" data-action="view" data-id="` + row.ujian_code + `"> Pembahasan </button>
					<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-primary" data-action="rank" data-id="` + row.ujian_code + `" data-mapelid="` + row.mapel_id + `">Ranking List</button>`;
					}
				}

				if (row.total_ujian < 1) {
                    if (row.total_soal < 1) {
                        return 'Belum Ada Soal.';
                    } else {
                        return `<div class="btn-group"><button type="button" class="btn btn-sm btn-info" data-action="view" data-id="`+row.ujian_code+`"><i class="fas fa-edit"></i> Mulai</button></div>`;
                    }
				}
			}, },
		],
		filter: false
	});
    },
}
</script>
<style type="text/css" scoped>
</style>